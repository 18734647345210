<template>
  <v-layout column>
    <InnerTabs :value.sync="activeTab">
      <InnerDefaultTab
        v-for="tab in tabs"
        :key="tab.value"
        :value="tab.name"
        :active="tab.value === tabs[activeTab].value"
      ></InnerDefaultTab>
    </InnerTabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="tab in tabs" :key="tab.value">
        <PatientCardSettingsForm
          class="mx-3"
          v-if="tab.value === tabs[activeTab].value"
          :cardType="tab.value"
          :isLoading.sync="loading"
          @success="handleSuccess"
          @error="handleError"
        ></PatientCardSettingsForm>
      </v-tab-item>
    </v-tabs-items>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

const PHYSIOTHERAPEUTIC = 0;
const UROGYNECOLOGICAL = 1;
const PEDIATRIC = 2;

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          value: PHYSIOTHERAPEUTIC,
          name: "Karta fizjoterapeutyczna",
        },
        {
          value: UROGYNECOLOGICAL,
          name: "Karta uroginekologiczna",
        },
        {
          value: PEDIATRIC,
          name: "Karta pediatryczna",
        }
      ],
    };
  },
  components: {
    InnerTabs: () => import("@/components/tabs/InnerTabs"),
    InnerDefaultTab: () => import("@/components/tabs/InnerDefaultTab"),
    PatientCardSettingsForm: () =>
      import("@/components/forms/settings/PatientCardSettingsForm"),
  },
};
</script>